<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <ul>
          <li v-for="i in data.records" :key="i.id" @click="toDetails(i.id)">
            <p class="content"><span>&bull;</span>{{i.title}}</p>
            <p class="time">{{i.time}}</p>
          </li>
        </ul>
        <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import pagination from "@/components/public/pagination";

export default {
  name: "literature",
  components: {
    leftAndRight,
    card,
    pagination
  },
  data() {
    return {
      data:{},
      current:1
    }
  },
  mounted() {
    this.getDataList()
  },
  methods:{
    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '专家文献',
          belong: 1,
          current: this.current,
          size: 10
        }
      }).then((res) => {
        let forms=res.data.records
        for(let i in forms){
          let pic =forms[i].pic
          if(pic!=null){
            forms[i].pic=this.$constContent.imgUrl+pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].time=this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        this.data=res.data
      })

        const ths = this
        this.$http.get('/safe/getIndexInformation', {
          params: {
            name: '热门文章',
          }
        }).then((res) => {
          let forms = res.data

          for (let i in forms) {
            let pic = forms[i].pic
            if (pic != null) {
              forms[i].pic = this.$constContent.imgUrl + pic
            }
            let time = forms[i].time;
            let date = new Date(time);
            res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
          }
          let Cards=[]
          let Card={}
          Card.title='热门文章'
          Card.id=0
          Card.content = res.data
          Cards.push(Card)
          ths.$nextTick(() => {
              ths.$refs.card.init(Cards)
          })
        })
    },
    toPage(e){
      this.current=e
      this.getDataList()
    },
    toDetails(id){
      this.$router.push('/literatureDetails?id='+id)
      this.$emit('getTitle','文献详情')
    }
  }
}
</script>

<style scoped>
ul{
  list-style: none;
  padding-left: 0;
}
ul li {
  width: 100%;
  height: 48px;
}

ul li p {
  font-size: 14px;
  color: #666666;
}

.content {
  width: 60%;
  float: left;
  font-weight: 800;
  font-size: 16px;
  color: #050001;
  cursor: pointer;
}
.content span{
  margin-right: 10px;
}

.time {
  width: 30%;
  float: right;
  cursor: pointer;
}

.search {
  width: 100%;
  height: 40px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}
</style>